@import url('https://fonts.googleapis.com/css?family=Open+Sans|Pacifico');

* {
    box-sizing: border-box;
}

body, input, button {
    font-family: 'Open Sans', sans-serif;
}

body {
    background: #ffffff;
}

.box {
  height: 80%;
  margin: 20px;
  padding: 20px;
  padding-top: 5px;
  background-color: #ffffff;
}
.flexbox-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 2px;
  border: solid 1px #e6eaed;
  background-color: #ffffff;
}

.title {
  font-size: 14px;
  color: #1F2A37;
  font-weight: 600;
  padding: 10px;
}

.content-box {
  border-radius: 2px;
  border: solid 1px #e6eaed;
  background-color: #ffffff;
}

#app {
    max-width: 100%;
    width: 900px;
    margin: 2em auto;
    background: white;
    padding: 1em;
}

h1 {
    color: #1F2A37;
}

 .btn {
   background-color: #3f51b5;
   align-self: flex-end;
   border: none;
   border-radius: 5px;
   color: white;
   padding: 10px 10px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 16px;
   margin: 4px 2px;
   transition-duration: 0.4s;
   cursor: pointer;
   width: 150px;
}
 button, div#app {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.delete-btn {
    background-color: #e74c3c;
}

.listheader {
  border: 0.2px solid #D3D3D3;
  display: flex;
  flex-direction: row;
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
}

.item {
  flex: 1;
  text-align:center;
  font-weight: 'bold';
  font-size: 13px;
  color: #333;
}
.listData {
  display: flex;
  margin-top: 1px;
  flex-direction: row;
  border: 0.1px solid #ececec;
}
.listData:hover {
  background-color: #ffffff;
}
.device-name {
  flex: 1;
  text-align: center;
  padding: 10px;
  color: #051b2c;
  font-weight: bold;
  font-size: 16px;
}

.data {
  flex: 1;
  text-align: center;
  padding: 10px;
  align-self: center;
  color: #051b2c;
  font-size: 16px;
}
