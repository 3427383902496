@import url(https://fonts.googleapis.com/css?family=Open+Sans|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Pacifico);
.load-view {
  height: 80%;
  margin: 20px;
  padding: 20px;
  background-color: #ffffff;
  align-items: center;
  text-align: center;
}
.box {
  height: 80%;
  margin: 20px;
  padding: 20px;
  background-color: #ffffff;
}
.listheader {
  border: 0.2px solid #D3D3D3;
  display: flex;
  flex-direction: row;
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
}
.item {
  flex: 1 1;
  text-align:center;
  font-weight: 'bold';
  font-size: 13px;
  color: #333;
}
.listData {
  display: flex;
  margin-top: 1px;
  flex-direction: row;
  border: 0.1px solid #ececec;
}
.listData:hover {
  background-color: #F8F8F8;
}
.message {
  flex: 1 1;
  text-align: center;
  padding: 10px;
  color: #051b2c;
  font-weight: bold;
  font-size: 16px;
}
.data {
  flex: 1 1;
  text-align: center;
  padding: 10px;
  align-self: center;
  color: #051b2c;
  font-size: 16px;
}

* {
    box-sizing: border-box;
}

body, input, button {
    font-family: 'Open Sans', sans-serif;
}

body {
    background: #ffffff;
}

.box {
  height: 80%;
  margin: 20px;
  padding: 20px;
  background-color: #ffffff;
}

#app {
    max-width: 100%;
    width: 900px;
    margin: 2em auto;
    background: white;
    padding: 1em;
}

h1 {
    color: #1F2A37;
}

 button {
    padding: 0.5em;
    margin: 2px;
    display: inline-block;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.19);
    border: 2px solid #16a085;
}
 button, div#app {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

button {
    background: #16a085;
    color: #fff;
    border: none;
    padding: 0.7em;
}

button {
    cursor: pointer;
}

.delete-btn {
    background-color: #e74c3c;
}

.listheader {
  border: 0.2px solid #D3D3D3;
  display: flex;
  flex-direction: row;
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
}

.item {
  flex: 1 1;
  text-align:center;
  font-weight: 'bold';
  font-size: 13px;
  color: #333;
}
.listData {
  display: flex;
  margin-top: 1px;
  flex-direction: row;
  border: 0.1px solid #ececec;
}
.listData:hover {
  background-color: #ffffff;
}
.device-name {
  flex: 1 1;
  text-align: center;
  padding: 10px;
  color: #051b2c;
  font-weight: bold;
  font-size: 16px;
}
.data {
  flex: 1 1;
  text-align: center;
  padding: 10px;
  align-self: center;
  color: #051b2c;
  font-size: 16px;
}

.noti-template {
  margin: 30px;
  padding: 20px 40px 20px 40px;
  border-radius: 2px;
  border: solid 1px #e6eaed;
  background-color: #ffffff;
  min-height: calc(100vh - 40px - 70px)
}

.noti-header {
  font-size: 32px;
  font-weight: bold;
  line-height: 0.83;
  letter-spacing: normal;
  color: #4f585f;
}

.block {
  background-color: #fff;
  box-shadow: 0 0 4px #dde1e4;
  width: 100%;
  margin-bottom: 1rem;
}

.block-header {
  margin: 25px 0px;
  padding: 10px 20px 10px 20px;
  background: #fafbfb;
}

.user-type-btns {
  padding: 10px 20px 10px 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.form-content {
  padding: 20px;
}

.form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //justify-content: flex-end;
  align-items: center;
}

.drop-down-container {
  //display: flex;
  align-items: center;
  margin: 0px 30px;
}

.MuiFormControl-root {
  width: 150px;
}

.input-lable {
  font-weight: 600;
  letter-spacing: .1px;
  font-size: 13px;
  color: #7b91a7;
  margin-bottom: 20px;
  margin-top: 10px;
}

.input-style {
  width: 30%;
  min-height: 30px;
}

.send-button {
  background-color: #4CAF50;
  /* Green */
  border: none;
  border-radius: 25px;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.send-button:hover {
  background-color: #4CAF50;
  color: white;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.margin-btm-20 {
  margin-bottom: 20px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.padding-left-20 {
  padding-left: 20px;
}

.label {
  padding: 10px;
}

.lang-select-block {
  display: flex;
  align-items: center;
  margin: 30px 30px 0px 40px;
  padding-left: 5px;
  background: #ebedef;
  width: 50%;
}
* {
    box-sizing: border-box;
}

body, input, button {
    font-family: 'Open Sans', sans-serif;
}

body {
    background: #ffffff;
}

.box {
  height: 80%;
  margin: 20px;
  padding: 20px;
  padding-top: 5px;
  background-color: #ffffff;
}
.flexbox-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 2px;
  border: solid 1px #e6eaed;
  background-color: #ffffff;
}

.title {
  font-size: 14px;
  color: #1F2A37;
  font-weight: 600;
  padding: 10px;
}

.content-box {
  border-radius: 2px;
  border: solid 1px #e6eaed;
  background-color: #ffffff;
}

#app {
    max-width: 100%;
    width: 900px;
    margin: 2em auto;
    background: white;
    padding: 1em;
}

h1 {
    color: #1F2A37;
}

 .btn {
   background-color: #3f51b5;
   align-self: flex-end;
   border: none;
   border-radius: 5px;
   color: white;
   padding: 10px 10px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 16px;
   margin: 4px 2px;
   transition-duration: 0.4s;
   cursor: pointer;
   width: 150px;
}
 button, div#app {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.delete-btn {
    background-color: #e74c3c;
}

.listheader {
  border: 0.2px solid #D3D3D3;
  display: flex;
  flex-direction: row;
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
}

.item {
  flex: 1 1;
  text-align:center;
  font-weight: 'bold';
  font-size: 13px;
  color: #333;
}
.listData {
  display: flex;
  margin-top: 1px;
  flex-direction: row;
  border: 0.1px solid #ececec;
}
.listData:hover {
  background-color: #ffffff;
}
.device-name {
  flex: 1 1;
  text-align: center;
  padding: 10px;
  color: #051b2c;
  font-weight: bold;
  font-size: 16px;
}

.data {
  flex: 1 1;
  text-align: center;
  padding: 10px;
  align-self: center;
  color: #051b2c;
  font-size: 16px;
}

